<template>
  <span class="tempspan">
    <el-popover
    placement="top-start"
    width="500"
    close-delay='0'
    @after-leave="nameeditHide"
    ref="popover"
    trigger="click">
    <div class="temp" v-loading='loading.tagLoading'>
    <div class="muban">
        <el-input 
        @input.native="selectTag" 
        v-model="tagKeyword" 
        placeholder="搜索模板名称"></el-input>
        <p 
        @mouseenter="enterTemplate(item)" 
        @mouseleave="leaveTemplate" 
        @click="matchProductInfo(item)" 
        v-for="item in templateList" 
        :key="item.id">
        <span class="on-line" style="width:120px;cursor:pointer">{{item.name}}</span>
        </p>
        <el-button 
        @click="$router.push('/messageTemplate')" 
        style="width:150px;border-top:1px solid #E4E7ED;position:absolute;left:0;bottom:0" 
        type="text">添加模板</el-button>
    </div>
    <div>
        <p style="padding-left:15px">模板名称: {{templateName?templateName:'请选择模板'}}</p>
        <div 
        v-dompurify-html="templateContent" 
        style="white-space:pre-line;margin-top:10px;font-size:14px;color:#999999;line-height:25px;padding-left:12px" 
        v-show="templateName">
        </div>
        <div 
        style="text-align:center;margin-top:100px;font-size:14px;color:#999999" 
        v-show="!templateName">
            请选择具体模板
        </div>
    </div>
    </div>
    <span slot="reference">
        <span  @click="tagListPage">
            选择模板
            <i class="el-icon-caret-bottom c"></i>
        </span>
    </span>
    </el-popover>
</span>
</template>

<script>
export default {
    data(){
        return{
            templateName:'',
            templateList:[],
            templateContent:'',
            tagKeyword:'',
        }
    },
    methods:{
        tagListPage(){},
        selectTag(){},
        nameeditHide(){}
        
    }
}
</script>

<style>

</style>